import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';

import withResponsiveListeners from '../../../../utils/Responsive';

import { UNITS } from './units';
import { COLUMNS } from './utils';

const S = {
    dialogBody: styled.div`
        padding: 7px 0;
        display: block;

        ${({ isMobile }) => !isMobile && css`
            column-count: 2;
        `}
    `,
    unitSelector: styled.div`
        margin: 0 auto;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    label: styled.div`
        width: 120px;
        color: var(--text-secondary-color);
    `
};

const UnitSetting = ({ column, unit, store, onChange }) => {
    const type = store.index[column].unitType;
    const handleChange = e => onChange(store.index[column], e.target.value);

    return (
        <S.unitSelector>
            <S.label>{ COLUMNS[column].title }</S.label>

            <Select
                key={`select-${column}`}
                className='ccad-units-select'
                onChange={handleChange}
                value={unit.id}
                data-what='ccad-units-select'
                data-which={`${column}`}
            >
                {
                    Object.keys(UNITS[type])
                        .map(id =>
                            <MenuItem
                                key={`units-${column}-${id}`}
                                value={id}
                                data-what={`ccad-units-item-${column}`}
                                data-which={`${id}`}
                            >
                                { UNITS[type][id].title }
                            </MenuItem>
                        )
                }
            </Select>
        </S.unitSelector>
    );
};

UnitSetting.propTypes = {
    column: PropTypes.string,
    unit: PropTypes.object,
    store: PropTypes.object,
    onChange: PropTypes.func
};

class UnitsDialog extends React.Component {
    constructor (props) {
        super(props);

        const { index } = props.dialog.params.store.unitsStore;

        this.state = Object.keys(index)
            .reduce((acc, col) =>
                ({
                    ...acc,
                    [col]: index[col].unit
                }),
            {});
    }

    onSubmit = () => {
        this.props.dialog.close(this.state);
    };

    onClose = () => {
        this.props.dialog.dismiss();
    };

    setUnitState = (unit, value) => {
        this.setState({ [unit.column]: UNITS[unit.unitType][value] });
    };

    render () {
        const { unitsStore } = this.props.dialog.params.store;
        const { screen } = this.props;
        const isMobile = !['md', 'lg'].includes(screen.size);

        return (
            <Dialog>
                <Dialog.Header onClose={this.onClose}>
                    <Dialog.Title>{ gettext('Units of measurement') }</Dialog.Title>
                </Dialog.Header>

                <Dialog.Inner>
                    <S.dialogBody isMobile={isMobile}>
                        {
                            Object.entries(this.state)
                                .map(([col, unit]) =>
                                    <UnitSetting
                                        key={`unit-setting-${col}`}
                                        column={col}
                                        unit={unit}
                                        store={unitsStore}
                                        onChange={this.setUnitState}
                                    />
                                )
                        }
                    </S.dialogBody>
                </Dialog.Inner>

                <Dialog.Buttons>
                    <Button
                        onClick={this.onSubmit}
                        variant='primary'
                        data-what='ccad-units-btn'
                        data-which='ok'
                    >
                        { gettext('OK') }
                    </Button>
                    <Button
                        onClick={this.onClose}
                        variant='secondary'
                        data-what='ccad-units-btn'
                        data-which='cancel'
                    >
                        { gettext('Cancel') }
                    </Button>
                </Dialog.Buttons>
            </Dialog>
        );
    }
};

UnitsDialog.propTypes = {
    dialog: PropTypes.object,
    screen: PropTypes.object
};

export default withResponsiveListeners(UnitsDialog);
